/* global coreui */

/**
 * --------------------------------------------------------------------------
 * CoreUI Pro Boostrap Admin Template (3.4.0): loading-buttons.js
 * License (https://coreui.io/pro/license)
 * --------------------------------------------------------------------------
 */

document.querySelectorAll('.c-loading-button').forEach(element => {
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  element.onclick = () => {
    element.addEventListener('start.coreui.loading-button', () => {
      console.log('start')
    })
    element.addEventListener('stop.coreui.loading-button', () => {
      console.log('stop')
    })
    element.addEventListener('complete.coreui.loading-button', () => {
      console.log('complete')
    })
    const lb = coreui.LoadingButton.getInstance(element)
    lb.start()
  }
})
